import Image from 'next/image'
import Link from 'next/link'
import footer from '/assets/images/footer.png'
import wave from '/assets/images/wave.svg'
import searchArrow from '/assets/images/searchArrow.svg'
import facebook from '/assets/images/facebook.png'
import instagram from '/assets/images/instagram.png'
import youtube from '/assets/images/youtube.png'
import sister1 from '/assets/images/sister1.png'
import sister2 from '/assets/images/sister2.png'
import arrowRightBlack from '/assets/images/arrowright-black.svg'
import dot from '/assets/images/dot.svg'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'


export default function Footer () {
  const router = useRouter()
  const [isImage, setIsImage] = useState(false)
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [year, setYear] = useState('')
  useEffect(() => {
    if (
      router?.pathname == '/team' ||
      router?.pathname === '/guided-group-trips'
    ) {
      setIsImage(true)
    }
    const d = new Date()
    setYear(d.getFullYear())
  }, [router])
  const subscribe = () => {
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email.trim() || !emailRegex.test(email)) {
        setError('Please enter a valid email address')
      }else {
        setError('')
        setEmail('')
        setMessage('You have successfully subscribed.')
        setTimeout(() => {
          setMessage('')
        }, 5000)
      }
    } else {
      setError('Please enter email!')
    }
  }
  return (
    <footer className={` relative text-black ${isImage && '!pt-0 changeBG'}`}>
      <Image
        src={footer}
        className={`${
          isImage && 'hidden'
        } 530-767:top-[-33px] 530-767:rotate-[-1deg] 777-991:top-[-45px] 777-991:rotate-[-1deg] absolute 320-529:rotate-[-2deg] 320-529:-top-[38px] 320-529:h-[50px] 992-1199:-top-[52px] 1260-1365:-top-[63px] 1366-1439:-top-[68px] 1440-1599:-top-[72px] 1600-1679:-top-[78px] 768-991:-top-[10px] -top-[90px] left-0 w-[100vw] pointer-events-none`}
        alt='image'
        title='image'
      />
      <div className='flex flex-wrap h-full w-full 1600-1679:mt-[-10px]'>
        <div className='w-full lg:w-7/12 mt-[-10px] 768-991:mt-0 relative'>
          <div
            className={`footer-left 992-1023:border-none ${
              isImage && 'border-t-0 !rounded-tr-none'
            } 992-1199:px-6 768-991:px-6 w-full h-full 768-991:border-b-0 768-991:rounded-br-none 992-1023:rounded-br-none 768-991:rounded-r-none rounded-r-[30px] 768-991:border-t-0 border border-solid border-[#B6D6E2] border-t-0 bg-[#D6E8EF] px-72 2560:pl-[440px] 1260-1365:px-36 1366-1439:px-40 1680-1919:px-60 1600-1679:px-[200px] 1440-1599:px-40 pt-16 pb-8 before:content-[''] before:w-[30px] before:h-[20px] before:absolute before:top-[10px] before:right-[-15px] before:bg-[#D6E8EF] 768-991:before:hidden`}
          >
            <Image src={wave} alt='image' title='image' />
            <h4 className='  font-lato-bold font-bold text-[40px] 320-529:w-full 320-529:text-[24px] 320-529:leading-8 768-991:w-[100%] 768-991:text-[30px] 768-991:leading-9 992-1199:text-[30px] 992-1199:leading-9 1366-1439:text-[36px] 1260-1365:w-[100%]  leading-10 tracking-[-0.4px] w-[100%] py-6'>
              Subscribe to our newsletter for the latest offers and news.
            </h4>
            {message && (
              <span
                className={'font-poppins-medium text-[16px] text-[#3DABC2]'}
              >
                {message}
              </span>
            )}
            {error && (
              <span
                className={'font-poppins-medium text-[16px] text-[#ff0000]'}
              >
                {error}
              </span>
            )}
            <div className='flex items-center w-full border-b border-solid border-[#3DABC2] pt-2'>
              <input
                type='email'
                placeholder='enter your email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                className='w-full bg-transparent p-3 ps-0 320-529:text-[16px] text-[#3DABC2] font-poppins-semiBold  text-lg outline-none leading-[22px] tracking-[-0.18px]'
              />
              <Link prefetch
                    href='javascript:void(0)'>
                <Image
                  src={searchArrow}
                  alt='image'
                  title='image'
                  onClick={subscribe}
                />
              </Link>
            </div>

            <div className='flex items-center gap-8 768-991:flex-wrap pt-12 320-529:pt-9 pb-3'>
              <h5 className='font-lato-bold font-bold text-2xl 320-529:text-[20px] 992-1199:text-[20px] 1366-1439:text-[20px]  leading-10'>
                Follow us
              </h5>

              <div className='flex gap-3'>
                <Link prefetch

                  href='https://www.facebook.com/BluewaterDiveTravel/'
                  className='flex items-center justify-center rounded-[50%] bg-[#3DABC2] w-[50px] h-[50px]'
                  target='_blank'
                >
                  <Image
                    src={facebook}
                    alt='image'
                    title='image'
                    className='w-[20px] h-[20px]'
                  />
                </Link>
                <Link prefetch

                  href='https://www.instagram.com/bluewaterdivetravel/'
                  className='flex items-center justify-center rounded-[50%] bg-[#3DABC2] w-[50px] h-[50px]'
                  target='_blank'
                >
                  <Image
                    src={instagram}
                    alt='image'
                    title='image'
                    className='w-[20px] h-[20px]'
                  />
                </Link>
                <Link prefetch

                  href='https://www.youtube.com/channel/UCHzYqAtB5DbQeE6snjAfD1A'
                  className='flex items-center justify-center rounded-[50%] bg-[#3DABC2] w-[50px] h-[50px]'
                  target='_blank'
                >
                  <Image
                    src={youtube}
                    alt='image'
                    title='image'
                    className='w-[20px] h-[20px]'
                  />
                </Link>
              </div>
            </div>

            <div className='pt-6'>
              <h5 className='font-lato-bold font-bold text-2xl 320-529:text-[20px] 992-1199:text-[20px] 1366-1439:text-[20px]  leading-10'>
                Visit our sister sites
              </h5>
              <div className='flex items-center gap-12 768-991:flex-wrap pt-5 320-529:pt-8 320-529:gap-8'>
                <Link prefetch

                  href={'https://www.bluewaterphotostore.com/'}
                  target='_blank'
                >
                  <Image
                    src={sister1}
                    width={200}
                    height={100}
                    className='object-contain'
                    alt='images'
                  />
                </Link>
                <Link prefetch

                  href={'https://www.uwphotographyguide.com/'}
                  target='_blank'
                >
                  <Image
                    src={sister2}
                    width={200}
                    height={200}
                    className='object-contain'
                    alt='images'
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full lg:w-5/12 mt-[-10px] 768-991:mt-0'>
          <div
            className={`footer-righ 992-1023:border-none 992-1023:ps-6  ${
              isImage && 'border-t-0'
            } w-full border-t-0 h-full 768-991:pt-3 768-991:px-6 768-991:border-none 768-991:rounded-l-none 992-1023:rounded-l-none 992-1023:rounded-br-none  768-991:rounded-br-[30px] rounded-l-[30px] border border-solid border-[#B6D6E2] border-l-0 border-r-0 bg-[#D6E8EF] py-20 ps-16 pb-8`}
          >
            <h5 className='font-lato-bold font-bold text-2xl 320-529:text-[20px] 992-1199:text-[20px] 1366-1439:text-[20px]  leading-10 pb-3'>
              Explore
            </h5>
            <ul>
              <li>
                <Link prefetch

                  href='/destination-finder'
                  className='inline-flex group hover:text-black relative hover:pl-[25px] transition-all duration-600 ease-in-out items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px]'
                >
                  <Image
                    className={
                      'opacity-0 pointer-events-none transition-all duration-600 ease-in-out absolute -left-[20px] group-hover:left-0 group-hover:pointer-events-auto group-hover:opacity-100 inline-flex'
                    }
                    src={arrowRightBlack}
                    alt='image'
                    title='image'
                  />
                  Destinations
                </Link>
              </li>
              <li>
                <Link prefetch

                  href='/all-liveaboard'
                  className='inline-flex group hover:text-black relative hover:pl-[25px] transition-all duration-600 ease-in-out items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px]'
                >
                  <Image
                    className={
                      'opacity-0 pointer-events-none transition-all duration-600 ease-in-out absolute -left-[20px] group-hover:left-0 group-hover:pointer-events-auto group-hover:opacity-100 inline-flex'
                    }
                    src={arrowRightBlack}
                    alt='image'
                    title='image'
                  />
                  Liveaboards
                </Link>
              </li>
              <li>
                <Link prefetch

                  href='/all-resorts'
                  className='inline-flex group hover:text-black relative hover:pl-[25px] transition-all duration-600 ease-in-out items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px]'
                >
                  <Image
                    className={
                      'opacity-0 pointer-events-none transition-all duration-600 ease-in-out absolute -left-[20px] group-hover:left-0 group-hover:pointer-events-auto group-hover:opacity-100 inline-flex'
                    }
                    src={arrowRightBlack}
                    alt='image'
                    title='image'
                  />
                  Resorts
                </Link>
              </li>
              <li>
                <Link prefetch

                  href='/guided-group-trips'
                  className='inline-flex group hover:text-black relative hover:pl-[25px] transition-all duration-600 ease-in-out items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px]'
                >
                  <Image
                    className={
                      'opacity-0 pointer-events-none transition-all duration-600 ease-in-out absolute -left-[20px] group-hover:left-0 group-hover:pointer-events-auto group-hover:opacity-100 inline-flex'
                    }
                    src={arrowRightBlack}
                    alt='image'
                    title='image'
                  />
                  Guided Group Trips
                </Link>
              </li>
              <li>
                <Link prefetch

                  href='/article-list'
                  className='inline-flex group hover:text-black relative hover:pl-[25px] transition-all duration-600 ease-in-out items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px]'
                >
                  <Image
                    className={
                      'opacity-0 pointer-events-none transition-all duration-600 ease-in-out absolute -left-[20px] group-hover:left-0 group-hover:pointer-events-auto group-hover:opacity-100 inline-flex'
                    }
                    src={arrowRightBlack}
                    alt='image'
                    title='image'
                  />
                  Ideas
                </Link>
              </li>
              <li>
                <Link prefetch

                  href='/specials-list'
                  className='inline-flex group hover:text-black relative hover:pl-[25px] transition-all duration-600 ease-in-out items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px]'
                >
                  <Image
                    className={
                      'opacity-0 pointer-events-none transition-all duration-600 ease-in-out absolute -left-[20px] group-hover:left-0 group-hover:pointer-events-auto group-hover:opacity-100 inline-flex'
                    }
                    src={arrowRightBlack}
                    alt='image'
                    title='image'
                  />
                  Special Offers
                </Link>
              </li>
              <li>
                <Link prefetch

                  href='/about-us'
                  className='inline-flex group hover:text-black relative hover:pl-[25px] transition-all duration-600 ease-in-out items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px]'
                >
                  <Image
                    className={
                      'opacity-0 pointer-events-none transition-all duration-600 ease-in-out absolute -left-[20px] group-hover:left-0 group-hover:pointer-events-auto group-hover:opacity-100 inline-flex'
                    }
                    src={arrowRightBlack}
                    alt='image'
                    title='image'
                  />
                  About Us
                </Link>
              </li>
              <li>
                <Link prefetch

                  href='/contact-us'
                  className='inline-flex group hover:text-black relative hover:pl-[25px] transition-all duration-600 ease-in-out items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px]'
                >
                  <Image
                    className={
                      'opacity-0 pointer-events-none transition-all duration-600 ease-in-out absolute -left-[20px] group-hover:left-0 group-hover:pointer-events-auto group-hover:opacity-100 inline-flex'
                    }
                    src={arrowRightBlack}
                    alt='image'
                    title='image'
                  />
                  Contact
                </Link>
              </li>
            </ul>
            <h5 className='font-lato-bold font-bold text-2xl 320-529:text-[20px] 992-1199:text-[20px] 1366-1439:text-[20px]  leading-10 pb-3 pt-6'>
              Contact us
            </h5>
            <ul>
              <li>
                <Link prefetch

                  href='tel:310-915-6677'
                  className='flex items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px] hover:text-black'
                >
                  310-915-6677
                </Link>
              </li>
              <li>
                <Link prefetch

                  href='mailto:info@bluewaterdivetravel.com'
                  className='flex items-center gap-1 font-poppins-medium text-[15px]  text-[#91A6B1] leading-[30px] tracking-[-0.15px] hover:text-black'
                >
                  info@bluewaterdivetravel.com
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='footer-bottom 768-991:gap-3 768-991:flex-col 768-991:!py-10  pt-5 pb-28 bg-[#3DABC2] flex items-center justify-between'>
        <div className='flex flex-wrap h-full w-full'>
          <div className='lg:w-7/12 w-[58.333333%] 530-767:w-full 320-529:w-full ps-72 2560:pl-[440px] 1260-1365:ps-36 1366-1439:ps-40 1680-1919:ps-60 1600-1679:ps-52 1440-1599:ps-40 992-1199:ps-6 768-991:ps-6 530-767:ps-0 320-529:ps-0'>
            <span className='font-poppins-medium text-sm  leading-[22px] 530-767:block 320-529:block 530-767:mb-2 320-529:mb-2 530-767:text-center 320-529:text-center tracking-[-0.14px] text-[#B6D6E2]'>
              {year} © Blue Water Travel
            </span>
          </div>
          <div className='lg:w-5/12 w-[41.666667%] 530-767:w-full 320-529:w-full ps-16 992-1023:ps-6 992-1023:pe-6 777-991:pe-6 768-991:ps-0'>
            <span className='font-poppins-medium 992-1023:justify-end 777-991:justify-end 530-767:justify-center 320-529:justify-center text-sm  leading-[22px] tracking-[-0.14px] text-[#B6D6E2] flex items-center gap-3'>
              {/*<Link prefetch
               href='javascript:void(0)'>Privacy Policy </Link>*/}
              <Image src={dot} alt='image' title='image' />
              <Link prefetch
                    href='/terms-conditions'>Terms & Conditions</Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
